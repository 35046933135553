.creation-account__container {
  display: flex;
  flex-direction: column;

  .creation-account__label {
    display: inline-block;
    padding: 5px 10px;
    cursor: pointer;

    .creation-account__input-radio {
      display: none;
    }

    .creation-account__span {
      position: relative;
      line-height: 22px;
    }

    .creation-account__span::before,
    .creation-account__span::after {
      content: '';
    }

    .creation-account__span::before {
      border: 1px solid #40c9c9;
      width: 20px;
      height: 20px;
      margin-right: 10px;
      display: inline-block;
      vertical-align: top;
    }

    .creation-account__span::after {
      background: #40c9c9;
      width: 14px;
      height: 14px;
      position: absolute;
      top: 1.5px;
      left: 3px;
      transition: 300ms;
      opacity: 0;
    }

    .creation-account__input-radio:checked + .creation-account__span::after {
      opacity: 1;
    }
  }
}
