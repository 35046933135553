.progresses {
  display: flex;
  align-items: center;
}

.steps-active {
  display: flex;
  border: 1px solid #40c9c9;
  background-color: #fff;
  color: #342f6c;
  font-size: 0.8em;
  font-weight: bold;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 1rem 0 1rem;
}

.steps {
  display: flex;
  border: 1px solid #342f6c;
  background-color: #342f6c;
  color: #342f6c;
  font-size: 0.8em;
  font-weight: bold;
  width: 10px;
  height: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 1rem 0 1rem;
}

.steps-valid {
  display: flex;
  border: 1px solid #40c9c9;
  background-color: #40c9c9;
  color: #40c9c9;
  font-size: 0.8em;
  font-weight: bold;
  width: 10px;
  height: 10px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  margin: 0 1rem 0 1rem;
}

.question-form {
  color: #342f6c;
  font-weight: bold;
}

.declaration-check {
  color: #40c9c9;
}

.declaration-warning {
  color: #ff2e00;
}

.information-document {
  border: 1px solid #40c9c9;
  font-size: 0.75em;
  background: #ffffff;
  border-radius: 10px;
  color: #342f6c;
  padding: 1rem;
  margin-top: -3em;
}

.error-document {
  border: 1px solid #ff2e00;
  font-size: 0.75em;
  background: #ffffff;
  border-radius: 10px;
  color: #ff2e00;
  padding: 1rem;
  margin-top: -3em;
}

.input-file {
  opacity: 0;
  overflow: hidden;
  position: relative;
  z-index: -1;
}

.label-file {
  justify-content: space-between;
  font-size: 1em;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
  background: #ffffff;
  border-color: #342f6c;
  border-radius: 10px;
  color: #342f6c;
  padding: 1rem;
}

.label-file,
.label-file:hover {
  border-color: #342f6c;
}

.container-file {
  display: flex;
  align-items: center;
  justify-content: center;
}

.icon-upload {
  margin: auto;
}

.button-area {
  margin-top: 5em;
}

.file-container {
  display: flex;
  /* width: 27.5%; */
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5em;
  cursor: pointer;
  box-sizing: border-box;
  background: #ffffff;
  border-color: #342f6c;
  border-radius: 10px;
  color: #342f6c;
  border: solid 1px;
}

.file-icon {
  margin-right: auto;
  margin-left: 0.5rem;
  display: flex;
}

.file-name {
  display: flex;
  max-width: 80%;
  text-align: right;
  margin-right: 0.8rem;
}

.close-icon {
  margin-right: 0.5rem;
}

.nom-validation {
  font-weight: bolder;
  color: #342f6c;
}

.number-validation {
  display: flex;
  border: 1px solid #40c9c9;
  background-color: #fff;
  color: #342f6c;
  font-size: 0.8em;
  font-weight: bold;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* margin:0 1rem 0 1rem; */
}

.number-validation-contacts {
  display: flex;
  border: 1px solid #fccc69;
  background-color: #fff;
  color: #342f6c;
  font-size: 0.8em;
  font-weight: bold;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* margin:0 1rem 0 1rem; */
}

.number-empty-validation {
  display: flex;
  border: 1px solid #fff;
  background-color: #fff;
  color: #342f6c;
  font-size: 0.8em;
  font-weight: bold;
  width: 25px;
  height: 25px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* margin:0 1rem 0 1rem; */
}

.title-validation {
  font-size: 0.8em;
  /* margin:auto; */
}
.data-validation {
  font-weight: bold;
  color: #342f6c;
  /* margin:auto; */
}

.footer-validation {
  background-color: #40c9c9;
}

.container {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
  margin-top: 10%;
  /* background-color: red; */
}

.container-loading {
  display: flex;
  height: 100%;
}

.DepotDocument h2 {
  margin-top: 5%;
}

.container-button {
  display: flex;
  align-items: center;
  justify-content: center;
}

.planning-maternite {
  align-items: center;
}

.pre-nat {
  height: 10em;
  align-items: center;
  justify-content: center;
  border-radius: 10px 0px 0px 10px;
  background-color: #40c9c9;
  color: #0e0653;
  font-weight: bolder;
  padding: 1.5em;
  border: solid 5px white;
}

.date-nat {
  background-color: #0e0653;
  color: white;
  height: 10em;
  align-items: center;
  justify-content: center;
  padding: 1.5em;
  border: solid 5px white;
}

.post-nat {
  height: 10em;
  align-items: center;
  justify-content: center;
  border-radius: 0px 10px 10px 0px;
  background-color: #40c9c9;
  color: #0e0653;
  padding: 1.5em;
  border: solid 5px white;
}

.title-div-nat {
  font-weight: bolder;
  font-size: 1.25em;
  margin-bottom: 1em;
  height: 3rem;
}
.date-div-nat {
  font-weight: bolder;
  font-size: 1em;
}

.container-date {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.information-paternite {
  border: 1px solid #40c9c9;
  font-size: 0.75em;
  background: #ffffff;
  border-radius: 10px;
  color: #342f6c;
  padding: 1rem;
  padding-bottom: 0.5em;
}

.dates-pater-sup {
  display: flex;
  align-items: center;
  justify-content: center;
}

.container-form {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: flex-end;
  justify-content: space-around;
}

.date-item {
  width: 45%;
}

.document-ou {
  background-color: rgb(207, 207, 207);
  border-radius: 50%;
  padding: 0.75em;
}

.btn-kerij {
  box-sizing: border-box;
  background: #40c9c9;
  border-radius: 10px;
  font-weight: bolder;
  color: #0e0653;
  padding: 1rem;
}

.btn-kerij:hover {
  /* color:#FFFFFF; */
  filter: brightness(103%);
  background-color: #40c9c9;
  color: white;
}

.btn-kerij:focus {
  box-shadow: none;
  background-color: #40c9c9;
  color: #0e0653;
}

.btn-kerij-bleu-infodoc {
  box-sizing: border-box;
  background: #0e0653;
  border-radius: 10px;
  /* font-weight: bolder; */
  color: white;
  padding: 1rem;
  font-size: 0.75em;
}

.btn-kerij-bleu-infodoc:hover {
  color: white;
  filter: brightness(110%);
  background: #0e0653;
}
.btn-kerij-bleu-infodoc:focus {
  box-shadow: none;
  background: #0e0653;
  color: white;
}

.titre-type-doc {
  font-size: 1.5em;
  font-weight: bolder;
  margin-top: 1em;
}

.titre2-type-doc {
  font-size: 1.2em;
  font-weight: bolder;
  text-decoration: underline dotted;
  margin-top: 0.5em;
}

.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 100ms ease-in-out;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  overflow: auto;
}

.ReactModal__Overlay--before-close {
  opacity: 0;
}

.titre-type-doc-num {
  display: inline-flex;
  border: 1px solid #40c9c9;
  background-color: #fff;
  color: #342f6c;
  font-size: 1em;
  font-weight: bold;
  width: 30px;
  height: 30px;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  /* margin:0 1rem 0 1rem; */
}

.modal-info {
  cursor: pointer;
}

@media all and (max-width: 991px) {
  .declaration-h1 {
    text-align: center;
    font-size: 2em;
  }

  .btn-declaration {
    background-color: white;
    border-radius: 10px;
    margin-bottom: 0.5em;
    padding: 1em;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.15);
    min-height: 123px;
    width: 100%;
  }

  .font-p-pat {
    font-size: 11px;
    text-align: center;
  }

  .font-p-pat-label {
    font-size: 12px;
  }

  .font-p-pat-date {
    font-size: 10px;
  }
  .img-header-declaration {
    display: none;
  }
  .declaration-h2 {
    font-weight: bold;
    color: #0e0653;
    text-align: center;
  }
  .declaration-subtitle-2 {
    color: #0e0653;
    text-align: center;
  }
  .soutien-declaration {
    background-color: #fef5e1;
    padding: 1em;
  }
}

@media all and (min-width: 992px) {
  .declaration-h1 {
    text-align: left;
  }
  .btn-declaration {
    background-color: white;
    border-radius: 10px;
    margin: 1em;
    padding: 1em;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.15);
    min-height: 123px;
    width: 500px;
  }
  .img-header-declaration {
    width: auto;
    height: 50vh;
    position: absolute;
    right: 0;
    padding: 0em;
  }
  .declaration-h2 {
    font-weight: bold;
    color: #0e0653;
    text-align: left;
  }
  .declaration-subtitle-2 {
    color: #0e0653;
    text-align: left;
  }
  .soutien-declaration {
    background-color: #fef5e1;
    padding-left: 5em;
    padding-right: 5em;
    padding-top: 2em;
    padding-bottom: 2em;
  }
}

.declaration-h1 {
  color: white;
  font-weight: bold;
}

.btn-declaration:hover {
  box-shadow:
    rgba(50, 50, 93, 0.25) 0px 13px 27px -5px,
    rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;
  border: white;
  background-color: white;
}

.gradiant-hover-soutien:hover {
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(254, 248, 235, 1));
  border: white;
  background-color: white;
}

.gradiant-hover-declaration:hover {
  background-image: linear-gradient(rgba(255, 255, 255, 1), rgba(216, 255, 255, 1));
  border: white;
  background-color: white;
}

.btn-declaration-sub {
  text-align: left;
  font-size: 0.65em;
  color: #0e0653;
}
.btn-declaration-title {
  text-align: left;
  font-size: 0.95em;
  font-weight: bolder;
  color: #0e0653;
}

.btn-declaration-img {
  text-align: right;
  border-radius: 50%;
}

.services-declaration {
  background-color: #ffffff;
}
