.DocumentPreview {
  &__Page {
    canvas {
      width: 100% !important;
      height: auto !important;
      border-radius: 0.25rem;
    }
  }
}

.DocumentPreview {
  &--Default {
    border: 0.25rem #40c9c9 solid;
  }

  &--Failed {
    border: 0.25rem #ff2e00 solid;
  }

  &--Warning {
    border: 0.25rem #ff8039 solid;
  }
}
