.btn-valid-date {
  box-sizing: border-box;
  background: #0e0653;
  border-radius: 10px;
  font-weight: bolder;
  color: white;
  padding: 1rem;
  width: 500px;
}

.btn-valid-date:hover {
  box-sizing: border-box;
  background: white;
  border-radius: 10px;
  border-color: #0e0653;
  font-weight: bolder;
  color: #0e0653;
  padding: 1rem;
  width: 500px;
}

.btn-kerij-outline-choix {
  box-sizing: border-box;
  background: #ffffff;
  border-color: #342f6c;
  border-radius: 10px;
  color: #342f6c;
  padding: 1rem;
  width: 500px;
}

.btn-kerij-outline-choix:hover {
  filter: brightness(105%);
  background: #ffffff;
  border-color: #342f6c;
  color: #342f6c;
}

.btn-kerij-outline-choix:focus {
  box-shadow: none;
  background: #ffffff;
  border-color: #342f6c;
  color: #342f6c;
}

@media all and (max-width: 991px) {
  .titre-details {
    font-size: 25px;
  }

  .card-rh-header-phone {
    width: 105%;
    height: 3.5em;
  }

  .text-details-phone {
    font-size: 10px;
    line-height: normal;
  }

  .details-absence-text {
    font-size: 10px;
    line-height: normal;
  }

  .btn-valid-date {
    width: 200px;
  }

  .btn-kerij-outline-choix {
    width: 200px;
  }

  .champ-date {
    font-size: 11px;
    color: #0e0653;
  }

  .champ-time {
    font-size: 11px;
    color: #0e0653;
  }

  .text-proposition-date {
    font-size: 11.2px;
    float: inline-start;
  }

  .radio-date {
    margin-top: 0.5em;
  }
}

.style-check {
  border-radius: 10px;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding-left: 2em;
}

.style-check:hover {
  /* border-radius: 10px; */
  background-color: #40c9c9;
}

.checkbox-filtre {
  text-align: center;
  padding-left: 0.6em;
  padding-right: 0.6em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  align-items: center;
  cursor: pointer;
  border-color: #0e0653;
}

.checkbox-filtre:focus {
  box-shadow: none !important;
  border-color: #0e0653;
}
