.msg-pass {
  font-size: 0.85em;
  margin-bottom: 2em;
}

.msg-pass-create {
  font-size: 16px;
  margin-bottom: 2em;
}

.msg-code {
  font-size: 0.7em;
  margin-bottom: 2em;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

/* Chrome */
input::-webkit-inner-spin-button,
input::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Opéra*/
input::-o-inner-spin-button,
input::-o-outer-spin-button {
  -o-appearance: none;
  margin: 0;
}

.err {
  background: #ffffff;
  border-radius: 10px;
  color: #ff2e00;
  padding: 0.5rem;
}

.list-salarie {
  border: 1px solid #40c9c9;
  background: #ffffff;
  border-radius: 10px;
  color: #342f6c;
  padding: 1rem;
  cursor: pointer;
}

.input-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.label-password {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
}

.input-password {
  margin: auto;
}

.icon-show-password {
  position: absolute;
  z-index: 2;
  margin-right: 1rem;
  cursor: pointer;
}

.checkbox-business:checked {
  background-color: #40c9c9;
}
