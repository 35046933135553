.AbsencesDeleteCausePickerLabel {
  display: flex;
  white-space: nowrap;
}

.AbsencesDeleteCausePickerLabel > span {
  padding-right: 1rem;
}

.AbsencesDeleteCausePickerLabel > input {
  min-width: 21rem;
}
