.div-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.chart-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.chart-item {
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
  width: 90%;
  justify-content: center;
  align-items: center;
}

.pie-chart {
  width: 40%;
  height: 40%;
  top: 50%;
  left: 50%;
  position: absolute;
  transform: translate(-50%, -50%);
}

:root {
  --main-color: rgb(104, 117, 217);
  --main-color-hover: rgb(104, 117, 217, 0.2);
  --track-color: #ddd;
}

.customSlider {
  /* max width of your slider */
  /* Optional: Only If you want to center your slider in the page */
  margin: auto;
  height: 15px;
}

.customSlider-track {
  /* Top value to align your track to the center of your thumb */
  top: 8px;
  /* thickness of the track */
  height: 4px;
  /* default color of your track */
  background: var(--track-color);
}

.customSlider-track.customSlider-track-0 {
  /* color of the track before the thumb */
  background: var(--main-color);
}

.customSlider-thumb {
  cursor: pointer;
  /*color for the thumb */
  background: var(--main-color);
  /* shape of the thumb: circle */
  width: 20px;
  height: 20px;
  border-radius: 100%;
  /* remove default outline when selected */
  outline: none;
}

.customSlider-thumb:hover {
  box-shadow: 0 0 0 8px var(--main-color-hover);
}

.PieChartsContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  align-self: center;
  justify-content: center;
  margin: auto;
  transform: translate(-50%, -50%);
  height: 40%;
  width: 40%;
}

#legend {
  display: flex;
  justify-content: center;
  margin-top: 20px;
}
