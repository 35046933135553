.sous-titre-dropdown-business-vertical {
  background-color: #f9f9ff;
  font-size: 11px;
  color: rgba(14, 6, 83, 0.7);
  padding: 1em;
}

.titre-dropdown-business-vertical :hover {
  background-color: transparent;
}

/*BALISE HR*/
.section-title {
  /* centre verticalement les enfants entre eux */
  align-items: center;
  /* active flexbox */
  display: flex;
  /* garde le texte centré s’il passe sur plusieurs lignes ou si flexbox n’est pas supporté */
  text-align: center;
}

.section-title::after {
  /* nécessaire pour afficher les pseudo-éléments */
  content: '';
  /* partage le reste de la largeur disponible */
  flex: 1;
  /* l’unité « em » garantit un ratio constant avec la taille du texte */
  height: 0.025em;
  margin: 0;
}

/*VERTICALE NAVBAR DROPDOWN BUSINESS*/

.dropdown-business-vertical {
  position: relative;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content-business-vertical {
  display: none;
  position: absolute;
  left: 0;
  background-color: white;
  width: 220px;
  font-size: 1em;
  height: auto;
  text-align: center;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding: 0.7em;
  /* border: #0E0653 solid 1px  */
}

/* Links inside the dropdown */
.dropdown-content-business-vertical a {
  color: #0e0653;
  text-decoration: none;
  display: block;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif !important;
}

.dropdown-content-business-vertical label {
  color: #0e0653;
  text-decoration: none;
  display: block;
  font-size: 16px;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  padding-left: 0.5em;
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
}

.business-nav {
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  line-height: 15px;
  font-size: 16px;
}

.dropdown-content-business-vertical label:hover {
  background-color: #40c9c9;
  border-radius: 10px;
}

.dropdown-content-business-vertical input {
  align-items: center;
}

.dropdown-content-business-vertical input:checked {
  background-color: #40c9c9;
  border-color: #0e0653;
}

.style-check-business-vertical {
  border-radius: 10px;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding-left: 2em;
}

.style-check-business-vertical:hover {
  /* border-radius: 10px; */
  background-color: #40c9c9;
}

.style-input-check-business-vertical {
  width: 10px;
  height: 10px;
}

/* Show the dropdown menu on hover */
.dropdown-business-vertical .dropdown-content-business-vertical {
  text-align: left;
  display: block;
  position: absolute;
  border-radius: 10px;
  overflow: visible;
  z-index: 7;
}

.titre-dropdown-business-vertical {
  background-color: transparent;
  font-size: 15px;
  color: rgba(14, 6, 83, 0.5);
}

.sous-titre-dropdown-business-vertical {
  background-color: #f9f9ff;
  font-size: 11px;
  color: rgba(14, 6, 83, 0.7);
  padding: 1em;
}

.titre-dropdown-business-vertical :hover {
  background-color: transparent;
}

.checkbox-business-vertical {
  text-align: center;
  padding-left: 0.6em;
  padding-right: 0.6em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  align-items: center;
  cursor: pointer;
  border-color: #0e0653;
}

.checkbox-business-vertical:focus {
  box-shadow: none !important;
  border-color: #0e0653;
}

.pastille-initial-user {
  background: #ffffff;
  border: 1px solid #d6d6ec;
  border-radius: 20px;
  margin: 1em;
  padding: 0.2em;
}

.text-user {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
}

.pastille-nb-actions {
  background: #ffffff;
  border-radius: 20px;
  margin: 1em;
  padding: 0.2em;
  color: #0e0653;
}
