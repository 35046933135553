.container-fluid {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding-inline: 40px;
}

.fluid-chidren {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container-object {
  /* display: flex; */
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

/* .container-item-name {

} */

.container-item-value {
  font-weight: bold;
}

.hr-kerij {
  background-color: #333;
  /* background: linear-gradient(to right, #0E0653ed, #FCCC69); */
  float: center;
}

.button {
  display: flex;
  width: 12%;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.identifiant-profil {
  border-bottom: 1px solid #40c9c9;
  background: #ffffff;
  border-radius: 0px;
  color: #342f6c;
}

.input-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.label-password {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
}

.input-password {
  margin: auto;
}

.icon-show-password {
  position: absolute;
  z-index: 2;
  margin-right: 1em;
  cursor: pointer;
}

.footer-profil {
  background-color: #40c9c9;
}

.btn-profil {
  width: 200px;
  font-size: 0.8em;
}
