.dashboard-choix-actif {
  color: #342f6c;
  border-bottom: #40c9c9 solid 3px;
  font-weight: bolder;
  font-size: 1.25em;
  padding-bottom: 1em;
}

.creer-arret:hover {
  box-shadow: 2px 2px 2px #0e0653;
}

.creer-arret {
  border-width: 0.1em;
  border-color: #0e0653;
}

.ajouter-contact {
  border-color: #0e0653;
}

.ajouter-contact:hover {
  border-color: #0e0653;
  box-shadow: 2px 2px 2px #0e0653;
}

.ajouter-contact-2 {
  border-color: #79d9d9;
}

.ajouter-contact-2:hover {
  border-color: #79d9d9;
}

.btn-annuler {
  border: none;
  color: grey;
  font-size: 14px;
}

.dashboard-choix-non-actif {
  color: gray;
  text-decoration: none;
  border-bottom: #d3d0eb solid 3px;
  font-weight: bolder;
  font-size: 1.25em;
  padding-bottom: 1em;
}

.notif-actions-rh {
  border: #d3d0eb solid 1px;
  border-radius: 10px;
  padding: 0.3em;
}

.filtre-actions-1 {
  background-color: #f9f9ff;
  border-radius: 10px;
  border: #d3d0eb solid 1px;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  line-height: 3em;
}
.filtre-actions-2 {
  /* padding-left: 1em;
    padding-right: 1em;
    padding-top: 0.5em; */
  /* line-height: 1em; */
}
.line-height-filtres {
  line-height: 3em;
}

.custom-row {
  --bs-gutter-x: 0 !important;
}

.card-actions-rh-header {
  background-color: #0c064b;
  color: #ffffff;
  line-height: 100%;
  padding: 1em;
  border-radius: 10px 10px 0px 0px;
  border: #0c064b solid 1px;
}

.card-actions-rh-header-child {
  background-color: #0c064b;
  color: #ffffff;
  line-height: 100%;
  border: #0c064b solid 1px;
}

.card-actions-rh-header-modal {
  background-color: #0c064b;
  color: #ffffff;
  line-height: 100%;
  border-radius: 10px 10px 10px 10px;
  border: #0c064b solid 1px;
}

.card-actions-rh-header-details-absence {
  background-color: #0c064b;
  color: #ffffff;
  line-height: 100%;
  border-radius: 10px 10px 10px 10px;
  border: #0c064b solid 1px;
}

.card-actions-rh-header-yellow {
  background-color: #efd6a9;
  color: #0c064b;
  line-height: 100%;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.55em;
  padding-bottom: 0.55em;
  border-radius: 10px 10px 0px 0px;
  border: #efd6a9 solid 1px;
}

.notif-icon {
  font-size: 18pt;
}

.suivi-absences {
  font-size: 12px;
  color: #40c9c9;
}

.voir-profil {
  font-size: 0.8em;
}

.no-p-margin {
  margin: 0;
  font-size: 13px;
}

.no-h2-margin {
  margin: 0;
  font-size: 25px;
}

.gerer-notif {
  background-color: #79d9d9;
  border-radius: 10px;
}

.gerer-notif:hover {
  background-color: #79d9d9;
  color: black;
}

.gerer-notif :focus {
  background-color: #79d9d9;
}

.content-mid {
  line-height: 35px;
}

.pastille-verte {
  border-radius: 20px;
  background-color: #40c9c9;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 0.8em;
  font-weight: bolder;
  color: #0e0653;
}

.pastille-rouge {
  border-radius: 20px;
  background-color: red;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 0.8em;
  font-weight: bolder;
  color: #0e0653;
}

.card-rh-header-masked {
  background-color: #eae9f4;
  color: #0e065380;
  line-height: 100%;
  padding: 1em;
  border-radius: 10px 10px 0px 0px;
  border: #0c064b solid 1px;
}

.card-rh-header-masked-absence {
  background-color: #ffffff;
  color: #0e0653;
  line-height: 100%;
  padding: 1em;
  border-radius: 10px 10px 0px 0px;
  border: #0c064b solid 1px;
}

.card-rh-header-child-masked {
  background-color: #ffffff;
  color: #0e0653;
  line-height: 100%;
  border: #0c064b solid 1px;
}

.card-rh-masked-body {
  border-left: #0c064b solid 1px;
  border-right: #0c064b solid 1px;
  padding-top: 1em;
  /* padding-bottom: 1em; */
  line-height: 4em;
}

.card-rh-masked-text {
  color: #0e0653;
}

.card-rh-masked-text-muted {
  font-size: 0.9em;
}

.card-rh-masked-footer {
  border: #0c064b solid 1px;
  border-radius: 0px 0px 10px 10px;
  font-size: 0.8em;
}

.card-rh-header-poste {
  background-color: white;
  color: #0c064b;
  line-height: 100%;
  padding: 1em;
  border-radius: 10px 10px 0px 0px;
  border: #0c064b solid 1px;
}

.card-rh-poste-body {
  border-left: #0c064b solid 1px;
  border-right: #0c064b solid 1px;
  padding-top: 1em;
  /* padding-bottom: 1em; */
  line-height: 4em;
}

.card-rh-poste-text {
  color: #0e0653;
}

.card-rh-masked-text-muted {
  font-size: 0.9em;
}

.card-rh-poste-footer {
  border: #0c064b solid 1px;
  border-radius: 0px 0px 10px 10px;
  font-size: 0.8em;
}
/* #0E065380 */

.pastille-verte-2 {
  border-radius: 50%;
  background-color: #40c9c9;
  padding-left: 0.8em;
  padding-right: 0.8em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 0.8em;
  font-weight: bolder;
  color: #0e0653;
}

.pastille-rouge-2 {
  border-radius: 50%;
  background-color: red;
  padding-left: 0.8em;
  padding-right: 0.8em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 0.8em;
  font-weight: bolder;
  color: #0e0653;
}

.card-actions-rh-body {
  border-left: #0c064b solid 1px;
  border-right: #0c064b solid 1px;
  padding-top: 1em;
  /* padding-bottom: 1em; */
  line-height: 3.3em;
}

.card-actions-rh-body-child {
  padding-top: 1em;
  /* padding-bottom: 1em; */
  line-height: 3.3em;
}

.card-actions-rh-body-yellow {
  border-left: #efd6a9 solid 1px;
  border-right: #efd6a9 solid 1px;
  padding-top: 1em;
  /* padding-bottom: 1em; */
  line-height: 3.3em;
}

.card-actions-rh-text {
  color: #0e0653;
}

.card-actions-rh-text-child {
  color: #0e0653;
  font-size: 1.2em;
}

.card-actions-rh-text-muted {
  font-size: 0.9em;
}

.card-actions-rh-text-muted-child {
  font-size: 0.9em;
}

.card-actions-rh-footer {
  border: #0c064b solid 1px;
  border-radius: 0px 0px 10px 10px;
  font-size: 0.8em;
}

.card-actions-rh-footer-yellow {
  border: #efd6a9 solid 1px;
  border-radius: 0px 0px 10px 10px;
  font-size: 0.8em;
}

.click-footer {
  cursor: pointer;
}

.btn-kerij-rh {
  box-sizing: border-box;
  border: #40c9c9 solid 1px;
  border-radius: 10px;
  font-weight: bolder;
  color: #0e0653;
  padding: 01rem;
}

.btn-kerij-refused {
  box-sizing: border-box;
  background: red;
  border-radius: 10px;
  font-weight: bolder;
  color: #0e0653;
  padding: 1rem;
}

.btn-kerij-refused:hover {
  /* color:#FFFFFF; */
  filter: brightness(103%);
  background-color: red;
  color: white;
}

.btn-kerij-rh:hover {
  /* color:#FFFFFF; */
  filter: brightness(103%);
  background-color: #40c9c9;
  color: white;
}

.btn-kerij-rh:focus {
  box-shadow: none;
  background-color: #40c9c9;
  color: white;
  border: #40c9c9 solid 1px;
}

.btn-kerij-rh:disabled {
  box-shadow: none;

  color: grey;
}

.retour-pro {
  color: #0c064b;
  margin-bottom: 2em;
}

.warning-text-visite {
  font-size: 15px;
  color: red;
  margin-top: 1em;
  margin-bottom: 0.5em;
}

.test {
  background-color: #0c064b;
}

.titre-rh {
  color: #342f6c;
  font-weight: bold;
}

.nom-profil {
  color: #342f6c;
  font-weight: bold;
  text-align: center;
  font-size: 32px;
}

.card-stats {
  text-align: center;
  padding: 1em;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px;
}

.card-title-stats {
  font-weight: bolder;
  margin-bottom: 2em;
}

.btn-kerij-bleu-rh {
  box-sizing: border-box;
  background: #0e0653;
  border-radius: 10px;
  font-weight: bolder;
  color: white;
  padding: 1rem;
}

.wrapper {
  display: absolute;
  align-items: stretch;
  margin-top: -60px;
}

a,
a:hover,
a:focus {
  /* color: inherit; */
  text-decoration: none;
  transition: all 0.3s;
}

#sidebar {
  min-width: 250px;
  max-width: 250px;
  min-height: 100vh;
  background: #fff;
  color: #0c064b;
  transition: all 0.3s;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 8px 3px 0px;
}

#sidebar .sidebar-header {
  padding: 30px;
  background: #fff;
  color: #0c064b;
}

#sidebar ul.components {
  padding: 0;
}

#sidebar ul p {
  color: #0c064b;
  padding: 10px;
  margin-top: 10px;
  background-color: rgb(247, 247, 247);
}

#sidebar ul li a {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 20px;

  font-size: 1em;
  display: block;
}
#sidebar ul li a:hover {
  color: #fff;
  background: #0c064b;
}

#sidebar ul a.active {
  color: #fff;
  background: #0c064b;
}
#sidebar ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #0c064b;
}

.dropdown-actions {
  position: relative;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  display: none;
  z-index: 7;
  position: absolute;
  right: 0;
  background-color: white;
  width: 250px;
  font-size: 1em;
  padding: 5px;
  height: auto;
  text-align: center;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding: 1em;
  /* border: #0E0653 solid 1px  */
}

/* Links inside the dropdown */
.dropdown-content a {
  color: #0e0653;
  text-decoration: none;
  display: flex;
}

.dropdown-content:hover {
  background-color: #40c9c9;
}

/* Show the dropdown menu on hover */
.dropdown-actions:hover .dropdown-content {
  display: flex;
  border-radius: 10px;
}

.mail-active {
  font-weight: bold;
  color: #0e0653;
}

.add-mail {
  font-weight: bold;
  color: #0e0653;
  width: 500px;
  height: 46px;
  margin-bottom: 0.5em;
}

.contacts-libelle {
  color: #0e0653;
}

.contacts-modif {
  color: #0e0653;
}

.contacts-add-btn {
  border: none;
  width: 10px;
  height: 10px;
}

.contacts-modif :hover {
  color: #0e0653;
  text-decoration: line-through;
  text-decoration-color: #0e0653;
}

.card-style {
  background-color: white;
}

.contact-header-style {
  background-color: #f9f9ff;
}

.dropdown-filtre {
  position: relative;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content-filtre {
  display: none;
  position: absolute;
  right: 0;
  background-color: white;
  width: 350px;
  font-size: 1em;
  height: auto;
  text-align: center;
  box-shadow:
    rgba(0, 0, 0, 0.19) 0px 10px 20px,
    rgba(0, 0, 0, 0.23) 0px 6px 6px;
  padding: 0.7em;
  padding-bottom: 0.2em;
  /* border: #0E0653 solid 1px  */
}

/* Links inside the dropdown */
.dropdown-content-filtre a {
  color: #0e0653;
  text-decoration: none;
  display: block;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif !important;
}
.dropdown-content-filtre label {
  color: #0e0653;
  text-decoration: none;
  display: block;
  font-size: 16px;
  padding-right: 0.5em;
  padding-top: 0.5em;
  padding-bottom: 0.3em;
  padding-left: 0.5em;
  font-family: 'Montserrat', sans-serif !important;
}

.dropdown-content-filtre label:hover {
  background-color: #40c9c9;
  border-radius: 10px;
}

.dropdown-content-filtre input {
  align-items: center;
}

.dropdown-content-filtre input:checked {
  background-color: #40c9c9;
  border-color: #0e0653;
}

.style-check {
  border-radius: 10px;
  justify-content: center;
  margin-top: 0.5em;
  margin-bottom: 0.5em;
  padding-left: 2em;
}

.check-contact {
  font-size: 12px;
}

.kerij-check:checked {
  background-color: #40c9c9;
  border-color: #0e0653;
}

.style-check:hover {
  /* border-radius: 10px; */
  background-color: #40c9c9;
}

.style-input-check {
  width: 10px;
  height: 10px;
}

/* Show the dropdown menu on hover */
.dropdown-filtre .dropdown-content-filtre {
  text-align: left;
  display: block;
  position: absolute;
  border-radius: 10px;
  overflow: visible;
  z-index: 7;
}

.titre-dropdown {
  background-color: transparent;
  font-size: 15px;
  color: rgba(14, 6, 83, 0.5);
}

.sous-titre-dropdown {
  background-color: #f9f9ff;
  font-size: 11px;
  color: rgba(14, 6, 83, 0.7);
  padding: 1em;
}

.titre-dropdown :hover {
  background-color: transparent;
}

.checkbox-filtre {
  text-align: center;
  padding-left: 0.6em;
  padding-right: 0.6em;
  padding-top: 0.6em;
  padding-bottom: 0.6em;
  align-items: center;
  cursor: pointer;
  border-color: #0e0653;
}

.checkbox-filtre:focus {
  box-shadow: none !important;
  border-color: #0e0653;
}

.voir-document {
  color: #0e0653;
}

.voir-document:hover {
  cursor: pointer;
  text-decoration: underline;
}

.hide-add-contact {
  display: none;
}

.test-center {
  align-items: center;
  line-height: 0.5em;
}
