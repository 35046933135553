.kerij-button {
  border: none;
  font-size: inherit;
  cursor: pointer;
  transition: background-color 0.3s ease;
  box-sizing: border-box;
  background: #40c9c9;
  border-radius: 10px;
  color: #0e0653;
  font-weight: bolder;
  padding: 1rem;
  margin: 0.5em auto 0;

  &:hover {
    color: #ffffff;
  }

  &.disabled {
    background-color: #ffffff;
    border: 1px solid #c4c4c4;
    color: #0e0653;
    cursor: not-allowed;

    &:hover {
      background-color: #c4c4c4;
    }
  }
}
