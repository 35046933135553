/* MOBILE */
@media all and (max-width: 994px) {
  .btn-interesse {
    display: none !important;
  }

  .retour {
    color: #0c064b;
    padding-top: 1em;
    padding-left: 1em;
  }

  .navbar-mobile-details-service {
    background-color: white;
    border-top: rgb(231, 231, 231) solid 1px;
    border-radius: 1em 1em 0 0;
    overflow: hidden;
    position: fixed;
    bottom: 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 15px 15px;
  }

  .navbar-mobile-details-service-items {
    width: 47.5%;
    text-align: center;
    padding: 0.5em;
    /* padding-left: calc(0.5rem + env(safe-area-inset-left));
      padding-right: calc(0.5rem + env(safe-area-inset-right)); */
  }

  .link-navbarmobile-details-service {
    padding-top: 0.75em;
  }

  .navbar-mobile-details-service-img {
    height: 1.5em;
    opacity: 0.75;
    margin-bottom: 0.5em;
    /* color:red; */
  }

  .navbar-mobile-details-service-text {
    font-size: 0.55em;
    letter-spacing: -0.5px;
    /* white-space: nowrap; */
    font-weight: bolder;
  }

  .title-navbar {
    color: #0e0653;
    font-weight: bolder;
  }

  .retour {
    color: #0c064b;
  }
}

/* LAPTOP */

@media all and (min-width: 995px) {
  .navbar-mobile-details-service {
    display: none;
  }

  .btn-interesse {
    display: inline-block;
  }

  .retour {
    display: none;
  }
}

/* This adds extra bottom padding on mobile browsers */
.safe-bottom {
  padding-bottom: env(safe-area-inset-bottom);
}
