@media all and (max-width: 991px) {
  .titre-details {
    font-size: 25px;
  }

  .card-rh-header-phone {
    width: 105%;
    height: 3.5em;
  }

  .text-details-infos {
    font-size: 15px;
    line-height: normal;
  }

  .details-absence-text-2 {
    font-size: 15px;
    line-height: normal;
  }
}
