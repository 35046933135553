@media all and (max-width: 991px) {
  .declaration-h1 {
    /* text-align: center;
        font-size:2em; */
    display: none;
  }
  .header-service {
    background: linear-gradient(to bottom, rgba(12, 5, 74, 1), rgba(255, 201, 201, 1));
    padding: 1em;
    position: sticky;
    top: 0px;
    z-index: 9;
  }

  .services-nav-icon {
    position: sticky;
    top: 90px;
    z-index: 9;
    background-color: white;
  }

  .link-service {
    text-decoration: none;
  }

  .service-content {
    margin-top: 8em;
  }

  .img-header-service {
    display: none;
  }

  .btn-service {
    display: none;
  }

  .btn-service-search {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.15);
    /* min-height:75px; */
    width: 100%;
  }

  .btn-service-search:hover {
    background-color: white;
  }

  .btn-service-search:focus {
    background-color: white;
  }

  .btn-service-search:active {
    background-color: white;
  }

  .btn-service-sub-search {
    display: none;
  }
  .btn-service-title-search {
    text-align: left;
    font-size: 0.75em;
    font-weight: bolder;
    color: #333;
  }

  .btn-service-img-search {
    text-align: right;
    border-radius: 10%;
  }

  .search-service {
    border-color: white;
    border-bottom-color: #0e0653;
    border-radius: 0;
  }

  .search-service:focus {
    border-color: white;
    background-color: white;
  }

  .div-btn-service-img-search {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color:#D3D0EB; */
    border-radius: 10px;
  }

  .img-card-service-finance {
    max-height: 20vh;
    height: 100%;
    width: 100%;
  }
  .img-card-service-gratuit {
    max-height: 20vh;
    height: 100%;
    width: 100%;
  }
  .img-card-service-information {
    max-height: 20vh;
    height: 100%;
    width: 100%;
  }
  .img-card-service-payant {
    max-height: 20vh;
    height: 100%;
    width: 100%;
  }
}

@media all and (min-width: 992px) {
  .header-service {
    background: linear-gradient(to bottom, rgba(12, 5, 74, 1), rgba(255, 201, 201, 1));
    padding: 5em;
    padding-bottom: 1em;
  }

  .img-header-service {
    width: auto;
    height: 50vh;
    position: absolute;
    right: 0;
    padding: 0em;
    z-index: 0;
  }

  .btn-service {
    background-color: #0e0653;
    border-radius: 10px;
    margin: 1em;
    padding: 1em;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.15);
    min-height: 123px;
    width: 500px;
    /* position: relative;*/
    /* z-index:1; */
  }

  .btn-service:hover {
    background-color: #0e0653;
  }

  .btn-service-search {
    background-color: #ffffff;
    border-radius: 10px;
    padding: 1em;
    box-shadow: 0px 3px 2px rgba(0, 0, 0, 0.15);
    min-height: 123px;
    width: 100%;
    z-index: 1000000;
    position: relative;
  }

  .btn-service-search:hover {
    background-color: white;
  }

  .btn-service-search:focus {
    background-color: white;
  }
  .btn-service-search:active {
    background-color: white;
  }

  .btn-service-title-search {
    text-align: left;
    font-size: 0.95em;
    font-weight: bolder;
    color: #333;
  }

  .btn-service-img-search {
    text-align: right;
    border-radius: 10%;
  }

  .search-service {
    border-color: white;
    border-bottom-color: #0e0653;
    border-radius: 0;
  }

  .search-service:focus {
    border-color: white;
  }

  .div-btn-service-img-search {
    display: flex;
    align-items: center;
    justify-content: center;
    /* background-color:#D3D0EB; */
    border-radius: 10px;
  }

  .service-content {
    margin-top: 2em;
  }

  .img-card-service-finance {
    max-height: 20.7vh;
    width: 100%;
  }
  .img-card-service-gratuit {
    max-height: 20.7vh;
    width: 100%;
  }
  .img-card-service-information {
    max-height: 20.7vh;
    width: 100%;
  }
  .img-card-service-payant {
    max-height: 20.7vh;
    width: 100%;
  }
}

.btn-service:focus {
  background-color: #0e0653;
}

.btn-service-sub {
  text-align: left;
  font-size: 0.65em;
  font-weight: bolder;
  color: #40c9c9;
  z-index: -1;
}
.btn-service-title {
  text-align: left;
  font-size: 0.95em;
  font-weight: bolder;
  color: #ffffff;
}

.services-nav-icon {
  text-align: center;
  color: rgb(14, 6, 83, 0.75);
  font-size: 0.65em;
  overflow: auto;
  white-space: nowrap;
  border-bottom: 1px solid #d3d0eb;
  /* z-index:9;  */
}

.services-item,
.services-item-select {
  padding: 0.25rem 0;
  width: 6rem;
  text-align: center;
  cursor: pointer;
  text-decoration: none;
  border-bottom: 3px solid transparent;
  font-size: 10px;
  box-sizing: border-box;
}

.services-item-select,
.services-item:hover {
  color: rgb(14, 6, 83, 1);
  background-color: rgba(14, 6, 83, 0.1) !important;
}

.icon-service {
  width: 25px;
  height: 25px;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  margin-left: 0.75em;
  margin-right: 0.75em;
}

/* CARDS content service  */

.card-service {
  border-radius: 10px;
  border: none;
  /* width:18%; */
  margin-bottom: 2em;
  max-width: 75vw;
}

.card-service:hover {
  cursor: pointer;
}

.img-card-service-finance {
  /* height: 100%; */
  /* max-height: 25vh; */
  border-radius: 10px;
  /* padding:1em; */
  border: 3px solid #d4e3ff;
}

.img-card-service-information {
  /* height: 100%; */
  /* max-height: 25vh; */
  border-radius: 10px;
  /* padding:1em; */
  border: 3px solid #fcc6c7;
}

.img-card-service-payant {
  /* height: 100%; */
  /* max-height: 25vh; */
  border-radius: 10px;
  /* padding:1em; */
  border: 3px solid #d4b2ff;
}

.img-card-service-gratuit {
  /* height: 100%; */
  /* max-height: 25vh; */
  border-radius: 10px;
  /* padding:1em; */
  border: 3px solid #ffd3ba;
}

.pastille-img-service-nouveau {
  background-color: #ffed8c;
  top: 10px;
  color: #0e0653;
  left: 10px;
  opacity: 1;
  position: absolute;
  /* width: 25%; */
  border-radius: 10px 10px 10px 10px;
  padding: 5px;
  font-size: 0.75em;
  font-weight: bolder;
}

.pastille-finance {
  background-color: #d4e3ff;
  color: #0e0653;
  opacity: 1;
  position: absolute;
  /* width: 25%; */
  border-radius: 0px 10px 0px 10px;
  padding: 5px;
  font-size: 0.75em;
  font-weight: bolder;
  top: 100%;
  transform: translateY(-100%);
}

.div-img {
  position: relative;
}

.pastille-information {
  background-color: #fcc6c7;
  color: #0e0653;
  opacity: 1;
  position: absolute;
  /* width: 25%; */
  border-radius: 0px 10px 0px 10px;
  padding: 5px;
  font-size: 0.75em;
  font-weight: bolder;
  top: 100%;
  transform: translateY(-100%);
}

.pastille-gratuit {
  background-color: #ffd3ba;
  color: #0e0653;
  opacity: 1;
  position: absolute;
  /* width: 25%; */
  border-radius: 0px 10px 0px 10px;
  padding: 5px;
  font-size: 0.75em;
  font-weight: bolder;
  top: 100%;
  transform: translateY(-100%);
}

.pastille-payant {
  background-color: #d4b2ff;
  color: #0e0653;
  opacity: 1;
  position: absolute;
  /* width: 25%; */
  border-radius: 0px 10px 0px 10px;
  padding: 5px;
  font-size: 0.75em;
  font-weight: bolder;
  top: 100%;
  transform: translateY(-100%);
}

.pastille-img-service-exclu {
  background-color: #40c9c9;
  top: 10px;
  color: #0e0653;
  left: 10px;
  opacity: 1;
  position: absolute;
  /* width: 25%; */
  border-radius: 10px;
  padding: 5px;
  font-size: 0.75em;
  font-weight: bolder;
}

.pastille-img-service-best {
  background-color: #0e0653;
  top: 10px;
  color: #40c9c9;
  left: 10px;
  opacity: 1;
  position: absolute;
  /* width: 25%; */
  border-radius: 10px;
  padding: 5px;
  font-size: 0.75em;
  font-weight: bolder;
}

.card-title-service {
  font-weight: bolder;
  margin-top: 0.5em;
  font-size: 1.1em;
  margin-left: 0;
}

.card-footer-service {
  border: none;
  background-color: white;
  font-weight: bolder;
  font-size: 1em;
}

.icon-card-footer {
  margin: 0.5em;
}
