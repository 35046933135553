#ScanBotContainer {
  background-color: rgba(255, 255, 255, 0.8) !important;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.InputFileButton {
  width: 100%;
}
