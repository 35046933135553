.loading_analyse__container {
  display: flex;
  position: absolute;
  flex-direction: column;
  width: 100%;
  top: 50%;
  transform: translate(0, -50%);
  justify-content: center;
  align-items: center;
}

.loading_analyse__content {
  display: flex;
  position: relative;
  /* border: 2px solid #f3f3f3; */
  align-items: center;
  justify-content: center;
}

.loading_analyse__loading {
  display: flex;
  position: relative;
  border: 4px solid transparent;
  border-radius: 50%;
  background-image: linear-gradient(white, white),
    linear-gradient(to right, rgba(64, 201, 201, 1), rgba(64, 201, 201, 0));
  background-origin: border-box;
  background-clip: content-box, border-box;
  width: 120px;
  height: 120px;
  align-items: center;
  justify-content: center;
  -webkit-animation: spin 2s linear infinite;
  /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.loading_analyse__icon {
  position: absolute;
}

.loading_analyse__title_text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 24px;
  text-align: center;

  color: #0e0653;
}

.loading_analyse__content_text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  text-align: center;

  color: #0e0653;
  margin-bottom: 6vh;
}
