/*  FIXME: Mieux définir la typo */
h1 {
  /* font-size: 2rem; */
  color: #342f6c;
  font-weight: bold;
}

.hide-scroll {
  -ms-overflow-style: none; /* IE et Edge */
  scrollbar-width: none; /* Firefox */
}

.hide-scroll::-webkit-scrollbar {
  display: none; /* Webkit (Chrome, Safari) */
}

.form-legacy {
  display: flex;
  flex-direction: column;
  width: 350px;
  min-width: 100px;
  /* min-height: 300px; */
  padding: 10px 40px 0px 40px;
  margin-bottom: 5em;
}

.form-legacy > h1 {
  display: flex;
  justify-content: center;
  /* font-size: 2em; */
  margin-top: 0.25em;
  color: #342f6c;
}

.info {
  padding-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.form-legacy label {
  margin-bottom: 0.5em;
  color: #444;
  font-weight: lighter;
}

.form-legacy input {
  display: block;
  /* flex-direction: column; */
  margin-bottom: 15px;
  width: 100%;
  border-radius: 10px;
}

.form-legacy input,
.form-legacy select {
  padding: 10px 20px;
  border: 1px solid #d6d1d5;
  margin-top: 5px;
}
.form-legacy select {
  display: block;
  width: 100%;
}

.form-select {
  border-radius: 10px;
  padding: 10px 20px;
  border: 1px solid #d6d1d5;
}

.form-select:hover {
  box-shadow: none;
}

.form-select:focus {
  box-shadow: none;
  border: none;
  border: 1px solid #fccc69;
}

.form-legacy input[type='checkbox'] {
  display: inline-block;
  width: auto;
  margin-top: 2em;
  margin-right: 10px;
}

.form-date {
  border-radius: 10px;
  padding: 10px 20px;
  max-width: 12rem;
}

.form-file {
  border-radius: 10px;
  /* padding: 10px 20px; */
}

.form-file:focus {
  box-shadow: none;
  border: none;
  border: 1px solid #fccc69;
}

.form-date:focus {
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #fccc69;
}

.btn-submit {
  box-sizing: border-box;
  background: #40c9c9;
  border-radius: 10px;
  color: #0e0653;
  font-weight: bolder;
  padding: 1rem;
  border: none;
  width: 50%;
  margin: 0.5em auto 0px;
}

.btn-submit:hover {
  color: #ffffff;
  filter: brightness(103%);
}

.btn-submit:focus {
  box-shadow: none;
}

.error {
  color: #db2269;
  font-size: 0.5em;
  display: relative;
}

.submit {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.btn-signup {
  box-sizing: border-box;
  background: #ffffff;
  border-color: #fccc69;
  border-radius: 10px;
  color: #342f6c;
  padding: 1rem;
  font-size: 1rem;
}

.btn-signup:hover {
  filter: brightness(110%);
}

.btn-signup:focus {
  box-shadow: none;
}

.container-object {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}

.input-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.label-password {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
}

.input-password {
  margin: auto;
}

.icon-show-password {
  position: absolute;
  z-index: 2;
  margin-right: 1em;
  cursor: pointer;
}

.hidden {
  display: none !important;
}
