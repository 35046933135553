.card-admin {
  border-radius: 10px;

  /* width:18%; */
  margin-bottom: 2em;
  max-width: 75vw;
}

.title-admin {
  font-size: 16px;
  color: #0e0653;
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700;
  text-align: center;
}

.circle-bg {
  width: 100px;
  height: 100px;
  position: relative;
  background: #f6f4ff;
  border-radius: 50%;
  display: inline-block;
}

.btn-annuler {
  border: none;
  color: grey;
  font-size: 14px;
  cursor: pointer;
  margin-top: 0.8em;
  margin-right: 1em;
}

.btn-disabled {
  box-sizing: border-box;
  border-radius: 10px;
  font-weight: bolder;
  padding: 1rem;
  border: grey;
  color: grey;
  cursor: pointer;
}

.gerer-user {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400;
  font-size: 18px;
  text-align: left;
  align-items: center;
}

.settings-libelle {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400;
  font-size: 16.5px;
  align-items: center;
}

.creer-user {
  margin-top: 4px;
  border-width: 0.1em;
  border-color: #40c9c9;
}

.creer-user:hover {
  border-width: 0.1em;
  box-shadow: 2px 2px 2px #40c9c9;
}

.align-name-checkbox {
  margin-top: 0.4em;
}

.align-caret-checkbox {
  margin-top: 0.6em;
}

/* .input-search{
    border: none;
}

.input-search:hover{
    border: none;
}

.input-search:focus{
    border: none;
} */

.img-search-users {
  text-align: right;
  border-radius: 10%;
}

.input-search {
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color:#D3D0EB; */
  border-radius: 10px;
}

.form {
  position: relative;
  z-index: 0;
}

.form .fa-search {
  z-index: 0;
  position: absolute;
  top: 13px;
  left: 30px;
  color: #9ca3af;
}

.form-input {
  z-index: 0;
  text-indent: 33px;
  border-radius: 10px;
  border-color: #40c9c9;
}

.form-input:focus {
  box-shadow: 2px 2px 2px grey;
  border-color: #40c9c9;
}

.search-user {
  border-width: 0.1em;
  border-color: #40c9c9;
}

.search-user:hover {
  border-width: 0.1em;
  border-color: #40c9c9;
}

.search-user:focus {
  border-width: 0.1em;
  border-color: #40c9c9;
}

.modal-titre {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400;
  font-size: 12px;
}

.modal-sous-titre {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700;
  font-size: 20px;
  color: #0e0653;
}

.label-add-user {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700;
  font-size: 14px;
  color: #0e0653;
  margin-bottom: 0.5em;
}

.prenom-nom {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 700;
  font-size: 16px;
}

.poste {
  font-family: 'Montserrat', sans-serif !important;
  font-weight: 400;
  font-size: 14px;
}

.files-uploaded {
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  background-color: #d8ffff;
  font-weight: bolder;
  color: #342f6c;
  padding: 01rem;
}

.wait-files {
  box-sizing: border-box;
  border: none;
  border-radius: 10px;
  background-color: #f3f1ff;
  font-weight: bolder;
  color: #342f6c;
  padding: 01rem;
}

.modal-style {
  z-index: 5;
}

.modal-style-child {
  z-index: 7;
}

/* MANU NAV ADMIN */

/* .admin-nav {
    text-align: center;
    color:rgb(14, 6, 83, 0.75);
    font-size:1em;
    top: 60px;
    position: sticky;
    background-color: white;
    border-bottom: 1px solid #D3D0EB;

} */

.admin-navbar {
  background-color: #fff;
  border-right: 1px solid #dee2e6;
}

.admin-navbar .nav-link {
  font-size: 17px;
  font-weight: bold;
  color: #0c064b;
}

.admin-navbar .nav-link.active {
  background-color: #0c064b;
  color: #fff;
  border-radius: 5px;
}

.btn-add-service {
  margin-left: 2em;
}

.textarea-add-service {
  width: 100%;
  height: 150px;
  padding: 12px 20px;
  box-sizing: border-box;
  border: 2px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  resize: none;
}

.admin-menu {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  /* width:100px; */
  padding-left: 16px;
  padding-right: 16px;
  z-index: 99;
}

.textarea-description {
  white-space: pre-wrap;
  border-radius: 10px;
  width: 100%;
  border: 2px solid #ccc;
  transition: all 0.3s ease-in-out;
}

.textarea-description:focus {
  border-color: #0c064b;
}

.admin-menu:hover {
  color: rgb(14, 6, 83, 1);
  cursor: pointer;
  border-bottom: 3px solid #40c9c9;
  padding-bottom: 1em;
}

.card-service-admin {
  border-radius: 10px;
  border: none;
  /* width:18%; */
  margin-bottom: 2em;
  max-width: 75vw;
}

.short-text {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.selected-category {
  font-size: 18px;
  color: #0c064b;
}

.admin-menu-select {
  position: relative;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  color: rgb(14, 6, 83, 1);
  cursor: pointer;
  border-bottom: 3px solid #40c9c9;
  padding-bottom: 1em;
  padding-left: 16px;
  padding-right: 16px;
}

.img-admin {
  width: 25px;
  height: 25px;
  margin-bottom: 0.5em;
  margin-top: 0.5em;
  margin-left: 0.75em;
  margin-right: 0.75em;
}

.label-service {
  font-family: 'Montserrat', sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  text-transform: uppercase;
  color: #0e0653;
}
