.btn-valid-date {
  box-sizing: border-box;
  background: #0e0653;
  border-radius: 10px;
  font-weight: bolder;
  color: white;
  padding: 1rem;
  width: 500px;
}

.btn-valid-date:hover {
  box-sizing: border-box;
  background: white;
  border-radius: 10px;
  border-color: #0e0653;
  font-weight: bolder;
  color: #0e0653;
  padding: 1rem;
  width: 500px;
}

.btn-choice {
  background-color: #0e0653;
}

.btn-choice:disabled,
.btn-choice[disabled] {
  background-color: transparent;
}

.btn-choice:hover {
  background-color: white;
  border: 1px solid black;
  color: black;
}

.calandar-container {
  display: flex;
  justify-content: space-between;
}

.container-time {
  height: 485px;
  overflow: auto;
}

.calandar-block {
}

.time {
  border: 1px solid #0e0653;
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
  margin-left: 15px;
  margin-right: 20px;
}

.time:hover {
  cursor: pointer;
  background-color: #0e0653;
  color: white;
}

/* Calendar */

.react-calendar {
  width: 600px;
  max-width: 100%;
  background-color: #fff;
  color: #222;
  border-radius: 8px;
  box-shadow: 0 12px 24px rgba(0, 0, 0, 0.2);
  font-family: 'Montserrat', sans-serif !important;
  line-height: 3.125em;
  padding: 15px;
}

.react-calendar__navigation button {
  color: #0e0653;
  min-width: 44px;
  background: none;
  font-size: 16px;
  margin-top: 8px;
}

.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: #f8f8fa;
}

.react-calendar__navigation button[disabled] {
  background-color: #f0f0f0;
}

abbr[title] {
  text-decoration: none;
}

.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background: #f8f8fa;
  color: #0e0653;
  border-radius: 6px;
}

.react-calendar__tile--now {
  background: rgb(64, 201, 201);
  border-radius: 6px;
  font-weight: bold;
  color: #0e0653;
}

.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background: rgb(64, 201, 201);
  border-radius: 6px;
  font-weight: bold;
  color: #0e0653;
}

.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: #f8f8fa;
}

.react-calendar__tile--active {
  background: #0e0653;
  border-radius: 6px;
  font-weight: bold;
  color: white;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background: #0e0653;
  color: white;
}

.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: #f8f8fa;
}

/* Scroolbar */

.container-time::-webkit-scrollbar-track {
  background-color: #ffffff;
  border-radius: 10px;
}

.container-time::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
  margin-left: 10px;
}

.container-time::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #c7c7c7;
}

/* ==================== */

@media all and (max-width: 991px) {
  .label-mobile {
    font-size: 12px;
  }

  .titre-details {
    font-size: 25px;
  }

  .card-rh-header-phone {
    width: 105%;
    height: 3.5em;
  }

  .text-details-phone {
    font-size: 10px;
    line-height: normal;
  }

  .details-absence-text {
    font-size: 10px;
    line-height: normal;
  }

  .btn-valid-date {
    width: auto;
  }

  .champ-date {
    font-size: 11px;
    color: #0e0653;
    /* width: 50%; */
  }

  .champ-time {
    font-size: 11px;
    color: #0e0653;
  }

  .calandar-container {
    display: block;
  }

  .container-time {
    margin-top: 25px;
    margin: auto;
    width: 50%;
  }

  .calandar-block {
    margin: 0;
    width: 100%;
    margin-bottom: 25px;
  }
}

@media all and (min-width: 992px) {
  .label-mobile {
    display: none;
  }
}
