.AppLayout {
  &__Header {
    grid-area: header;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  &__Content {
    overflow-x: hidden;
    overflow-y: scroll;
  }

  &__Container {
    grid-area: content;
  }

  &__Footer {
    grid-area: footer;
    position: sticky;
    bottom: 0;
  }
}
