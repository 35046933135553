.policy-layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  margin: 2rem 0;
  padding-bottom: 3.5rem;

  &-header {
    margin-bottom: 3rem;
    color: #342f6c;
    font-weight: bold;
    text-align: center;
  }

  &-content {
    width: 80%;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      display: inline-block;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 1rem;
    line-height: 1.25;
  }

  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 28px;
  }

  h3 {
    font-size: 24px;
  }

  h4 {
    font-size: 20px;
  }

  h5 {
    font-size: 16px;
  }

  em {
    font-style: normal;
    font-weight: 700;
  }

  p {
    margin-top: 0.6rem;
    margin-bottom: 0.6rem;
  }

  p,
  ul,
  ol {
    font-size: 16px;
    font-weight: normal;
    margin-bottom: 1rem;
    text-align: justify;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
    color: blue;
  }

  ol {
    list-style-type: none;
    counter-reset: item;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    width: 100%;
  }

  ol > li {
    display: block;
    counter-increment: item;
    margin-bottom: 0.6rem;
    margin-top: 0.6rem;
    width: 100%;
    font-weight: 400;
    text-align: left;
  }

  ol > li:before {
    content: counters(item, '.') '. ';
    text-align: left;
    font-weight: bold;
    width: 100px;
    margin-right: 10px;
  }

  ol > ol {
    list-style-type: none;
    counter-reset: newItem;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  ol > ol > li {
    counter-increment: newItem;
    margin-bottom: 0.6rem;
    text-align: left;
    width: 100%;
    font-weight: lighter;
  }

  ol > ol > li:before {
    content: counter(item) '.' counter(newItem) '.';
    font-weight: bold;
    position: absolute;
    width: 100px;
    margin-right: 10px;
  }

  .not-before::before {
    content: '';
  }

  ul > li {
    list-style-type: disc;
    margin-bottom: 0.6rem;
  }

  table {
    width: 100%;
    table-layout: fixed;
    font-family: 'Montserrat', Helvetica, serif;
    font-size: 14px;
    font-weight: 400;
  }

  td {
    max-width: 50%;
    width: 50%;
  }

  table,
  tr,
  td {
    border: 1px solid black;
    border-collapse: collapse;
  }

  th {
    background-color: black;
    color: white;
    border: 1px solid white;
    text-align: center;
    padding: 10px;
  }

  td {
    vertical-align: top;
    padding: 10px;
  }

  .ul-table {
    list-style-type: none;
  }

  .ul-table > li {
    list-style-type: none;
  }

  .ul-table > li:before {
    content: '\2714\0020';
    margin-right: 10px;
  }

  td > ul > li::marker {
    align-self: center;
    font-size: 1.5rem;
  }
}
