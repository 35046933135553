.LoginRH form {
  display: flex;
  flex-direction: column;
  width: 350px;
  min-width: 100px;
  /* min-height: 300px; */
  padding: 10px 40px 0px 40px;
  margin-bottom: 5em;
}

.LoginRH form > h1 {
  display: flex;
  justify-content: center;
  font-size: 2em;
  margin-top: 0.25em;
  color: #342f6c;
}

.LoginRH .info {
  padding-bottom: 1em;
  padding-left: 0.5em;
  padding-right: 0.5em;
}

.LoginRH label {
  margin-bottom: 0.5em;
  color: #444;
  font-weight: lighter;
}

.LoginRH input {
  display: block;
  /* flex-direction: column; */
  margin-bottom: 15px;
  width: 100%;
  border-radius: 10px;
}

.LoginRH input,
.LoginRH select {
  padding: 10px 20px;
  border: 1px solid #d6d1d5;
  margin-top: 5px;
}
.LoginRH select {
  display: block;
  width: 100%;
}

.LoginRH .form-select {
  border-radius: 10px;
  padding: 10px 20px;
  border: 1px solid #d6d1d5;
}

.LoginRH .form-select:hover {
  box-shadow: none;
}

.LoginRH .form-select:focus {
  box-shadow: none;
  border: none;
  border: 1px solid #fccc69;
}

.LoginRH input[type='checkbox'] {
  display: inline-block;
  width: auto;
  margin-top: 2em;
  margin-right: 10px;
}

.LoginRH .form-date {
  border-radius: 10px;
  padding: 10px 20px;
}

.LoginRH .form-file {
  border-radius: 10px;
  /* padding: 10px 20px; */
}

.LoginRH .form-file:focus {
  box-shadow: none;
  border: none;
  border: 1px solid #fccc69;
}

.LoginRH .form-date:focus {
  border-radius: 10px;
  box-shadow: none;
  border: 1px solid #fccc69;
}

.LoginRH .error {
  color: #db2269;
  font-size: 0.5em;
  display: relative;
}

.LoginRH .submit {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
}

.LoginRH .btn-signup {
  box-sizing: border-box;
  background: #ffffff;
  border-color: #fccc69;
  border-radius: 10px;
  color: #342f6c;
  padding: 1rem;
  font-size: 1rem;
}

.LoginRH .btn-signup:hover {
  filter: brightness(110%);
}

.LoginRH .btn-signup:focus {
  box-shadow: none;
}

.LoginRH .container-object {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 30%;
}

.LoginRH .input-group {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

.LoginRH .label-password {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;
  justify-content: flex-end;
}

.LoginRH .input-password {
  margin: auto;
}

.LoginRH .icon-show-password {
  position: absolute;
  z-index: 2;
  margin-right: 1em;
  cursor: pointer;
}

.LoginRH .hidden {
  display: none !important;
}
