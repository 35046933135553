html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Montserrat', sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */

/* ------ */
/* CUSTOM CSS KERIJ */
/* ------ */
/*
.btn-kerij {
  box-sizing: border-box;
  background: #342F6C;
  border-radius: 63px;
  font-weight: bolder;
  color:#0E0653;
  padding:1.5rem;
}

.btn-kerij:hover {
  color:#FFFFFF;
  filter: brightness(120%);
}

.btn-kerij:focus {
  box-shadow: none;
}

.btn-kerij-outline {
  box-sizing: border-box;
  background: #FFFFFF;
  border-color:#342F6C;
  border-radius: 63px;
  color:#342F6C;
  padding:1.5rem;
}

.btn-kerij-outline:hover {
  filter: brightness(120%);
}

.btn-kerij-outline:focus {
  box-shadow: none;
}

.btn-kerij-sos {
  box-sizing: border-box;
  background: #FCCC69;
  border-radius: 63px;
  color:#342F6C;
  padding:1.5rem;
}

.btn-kerij-sos:hover {
  filter: brightness(95%);
}

.btn-kerij-sos:focus {
  box-shadow: none;
} */
