.btn-link {
  background: inherit;
}

.btn-kerij {
  box-sizing: border-box;
  background: #40c9c9;
  border-radius: 10px;
  font-weight: bolder;
  color: #0e0653;
  padding: 1rem;
  border: #40c9c9;
}

.btn-kerij:hover {
  /* color:#FFFFFF; */
  filter: brightness(103%);
  background-color: #40c9c9;
  color: white;
}

.btn-kerij:focus {
  box-shadow: none;
  background-color: #40c9c9;
  color: #0e0653;
  border: none;
}

.btn-kerij-outline {
  box-sizing: border-box;
  background: #ffffff;
  border-color: #342f6c;
  border-radius: 10px;
  color: #342f6c;
  padding: 1rem;
}

.btn-kerij-outline:hover {
  filter: brightness(105%);
  background: #ffffff;
  border-color: #342f6c;
  color: #342f6c;
}

.btn-kerij-outline:focus {
  box-shadow: none;
  background: #ffffff;
  border-color: #342f6c;
  color: #342f6c;
}

.btn-kerij-sos {
  box-sizing: border-box;
  background: #d40236;
  border-radius: 10px;
  color: #ffffff;
  padding: 1rem;
}

.btn-kerij-sos:hover {
  filter: brightness(105%);
  color: #ffffff;
}

.btn-kerij-sos:focus {
  box-shadow: none;
}

.header-kerij {
  background-image: linear-gradient(to bottom, rgba(14, 6, 83, 0.7), rgba(14, 6, 83, 0.7)),
    url('../../assets/header_kerij.jpg');
  background-position: 10% 50%;
  background-size: cover;
  padding: 5em;
}

.home-h1 {
  color: white;
  font-weight: bold;
}

.home-subtitle {
  color: white;
  margin-bottom: 5em;
}

@media all and (max-width: 984px) {
  .header-kerij {
    background-image: linear-gradient(to bottom, rgba(14, 6, 83, 0.7), rgba(14, 6, 83, 0.7)),
      url('../../assets/header_kerij.jpg');
    background-position: 10% 50%;
    background-size: cover;
    padding: 2em;
  }
  .home-h1 {
    margin-bottom: 1em;
    font-size: 1.5em;
  }
  .home-subtitle {
    display: none;
  }
}

.soutien-kerij {
  background: linear-gradient(to bottom, rgba(252, 204, 105, 1), rgba(252, 204, 105, 0.76));
  padding: 5em;
}

.home-h2 {
  font-weight: bold;
  color: #0e0653;
  text-align: left;
}

.home-subtitle-2 {
  color: #0e0653;
  text-align: left;
}

.btn-kerij-blanc {
  box-sizing: border-box;
  background: white;
  border-radius: 10px;
  font-weight: bolder;
  color: #0e0653;
  padding: 1rem;
  text-align: left;
}

.btn-kerij-blanc:hover {
  filter: brightness(95%);
  background: white;
  color: #0e0653;
  border-color: white;
}

.btn-kerij-blanc:focus {
  background: white;
  color: #0e0653;
  border-color: white;
}

@media all and (max-width: 984px) {
  .soutien-kerij {
    background: linear-gradient(to bottom, rgba(252, 204, 105, 1), rgba(252, 204, 105, 0.76));
    padding: 2em;
  }
  .home-h2 {
    font-size: 1.25em;
    text-align: center;
    margin-bottom: 1em;
  }
  .home-subtitle-2 {
    display: none;
  }
}

.services-kerij {
  background-color: #f5f5f5;
  padding: 5em;
}

.btn-kerij-bleu {
  box-sizing: border-box;
  background: #0e0653;
  border-radius: 10px;
  font-weight: bolder;
  color: white;
  padding: 1rem;
}

.btn-kerij-bleu:hover {
  color: white;
  filter: brightness(110%);
  background: #0e0653;
}
.btn-kerij-bleu:focus {
  box-shadow: none;
  color: white;
  background: #0e0653;
}

.img-services {
  background-image: url('../../assets/header_kerij.jpg');
  background-position: 10% 50%;
  background-size: cover;
}

@media all and (max-width: 984px) {
  .services-kerij {
    background-color: #f5f5f5;
    padding: 2em;
  }
  .home-subtitle-3 {
    display: none;
  }
  .img-services {
    display: none;
  }
}

.entretien-kerij {
  background-image: linear-gradient(to bottom, rgba(64, 201, 201, 0.8), rgba(64, 201, 201, 0.8)),
    url('../../assets/home1_kerij.jpg');
  background-position: 10% 50%;
  background-size: cover;
  padding: 5em;
}

.btn-rdv {
  transition-duration: 0.3s;
  border: 2px solid #0e0653;
  width: 350px;
  border-radius: 15px;
}

.btn-rdv:hover {
  background-color: #0e0653;
  color: white;
  box-shadow:
    0 12px 16px 0 rgba(0, 0, 0, 0.24),
    0 17px 50px 0 rgba(0, 0, 0, 0.19);
}
@media all and (max-width: 984px) {
  .entretien-kerij {
    padding: 2em;
  }
}
