.btn-accueil-all-absences {
  background-color: transparent;
  border: 1px solid #4fdddd;
  border-radius: 10px;
  height: 40px;
  margin-left: 1em;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 15px;
  font-family: 'Montserrat', sans-serif !important;
  color: #0e0653;
}

.btn-accueil-all-absences:hover {
  background-color: #4fdddd;
  filter: brightness(103%);
}

.header-absences {
  background: #0e0653;
  height: 50px;
  border-radius: 10px 10px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Montserrat', sans-serif !important;
  color: #ffffff;
}

.header-actions {
  background: #90f0f0;
  height: 50px;
  border-radius: 10px 10px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Montserrat', sans-serif !important;
  color: #0e0653;
}

.header-stats {
  background: #edebf9;
  height: 50px;
  border-radius: 10px 10px 0px 0px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 17px;
  font-family: 'Montserrat', sans-serif !important;
  color: #0e0653;
}

.header-absences .text-header-absences {
  line-height: 30px;
}

.header-actions .text-header-actions {
  line-height: 30px;
}

.header-stats .text-header-stats {
  line-height: 30px;
}

.no-p-margin {
  margin: 0;
  font-size: 10px;
}

.content-mid {
  line-height: 40px;
}

.text-absences {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif !important;
  color: #0e0653;
}

.text-actions {
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  font-family: 'Montserrat', sans-serif !important;
  color: #0e0653;
}

.pastille-verte {
  border-radius: 20px;
  background-color: #40c9c9;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 0.8em;
  font-weight: bolder;
  color: #0e0653;
}

.pastille-rouge {
  border-radius: 20px;
  background-color: red;
  padding-left: 1em;
  padding-right: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-size: 0.8em;
  font-weight: bolder;
  color: #0e0653;
}

.actions-notif {
  font-size: 12px;
}

.pastille-nb-actions {
  background: #ffffff;
  border-radius: 20px;
  margin: 1em;
  padding: 0.2em;
  color: #0e0653;
}

.card-dashboard {
  height: 350px;
}

.card-dashboard-stats {
  height: 620px;
}

.btn-position {
  position: relative;
}
