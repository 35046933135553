.kerij-box {
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
  max-width: 100%;
  padding: 1rem;
  margin: 0 auto;
}

.kerij-col {
  display: flex;
}

.kerij-box-content {
  text-align: start;
  margin-left: 1em;
}

.kerij-box-message {
  color: #342f6c;
  font-family: 'Montserrat', Helvetica, serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0;
  line-height: 20px;
  margin: 0;
  padding: 0;
}

.kerij-box-border {
  background-color: #ffffff;
  border-width: 1px;
  border-style: solid;
  border-radius: 10px;
}
