@media all and (max-width: 984px) {
  .footer {
    display: none;
  }
}

.footer {
  background: #ffffff;
  margin-top: 2em;
  height: 3em;
  width: 100%;
  line-height: 3;
  box-shadow: 0px 2px 4px rgba(52, 47, 108, 0.5);
}

.footer-container {
  padding: 1em;
  display: flex;
  justify-content: space-around;
  height: 100%;
  align-items: center;
}

.footer-kerij {
  font-weight: 700 !important;
}

.footer-links {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  line-height: normal;
}

.footer-item {
  color: #0e0653;
  font-family: 'Montserrat', Helvetica, serif;
  font-size: 12px;
  font-weight: 400;
  margin-right: 1rem;
  letter-spacing: 0;
}

.footer-lang {
  display: flex;
  align-items: center;
  color: #0e0653;
  line-height: normal;
}

.footer-lang-selected {
  font-family: 'Montserrat', Helvetica, serif;
  font-size: 12px;
  font-weight: 700;
  letter-spacing: 0;
  margin-left: 15px;
}

.footer-lang-selector {
  cursor: pointer;
  margin-left: 10px;
  bottom: 2px;
  position: relative;
}

.footer-lang-selector-icon {
  height: 10px;
  width: auto;
}

.footer-lang-icon {
  height: 18px;
  width: 18px;
  margin-left: 10px;
}
